import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import useClickOutside from 'common/helper/hooks/use-click-outside.hook';

import styles from './styles.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
  children: ReactNode;
}

const Modal = ({ active, setActive, children }: Props): ReactElement => {
  const ref = useRef(null);
  useClickOutside(ref, setActive, active);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.code === 'Escape' && active) setActive();
    };

    document.addEventListener('keydown', handleKeydown);
    return () => document.removeEventListener('keydown', handleKeydown);
  }, [setActive, active]);

  useEffect(() => {
    const handleScroll = (event: Event) => {
      event.preventDefault();
    };

    // Добавляем стили при открытии модалки
    if (active) {
      document.body.style.overflow = 'hidden';
      document.addEventListener('scroll', handleScroll, { passive: false });
    } else {
      // Удаляем стили при закрытии модалки
      document.body.style.overflow = '';
      document.removeEventListener('scroll', handleScroll);
    }

    // Очистка эффекта
    return () => {
      document.body.style.overflow = '';
      document.removeEventListener('scroll', handleScroll);
    };
  }, [active]);

  return (
    <div className={`${styles.container} ${active && styles.active}`}>
      <div className={styles.modal} ref={ref}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
