import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GetAllTelegramUsers, ITelegramUser } from '@EZenkof/common-recruiter-library';

import { IUsersSlice } from '../users.slice';

interface IResponse {
  count: number;
  users: ITelegramUser[];
}

export const getAllTelegramUsersReducer = createAsyncThunk<IResponse>(
  'users/getAllTelegramUsers',
  async (_, { rejectWithValue }) => {
    try {
      const data = await GetAllTelegramUsers();
      if (!data) throw new Error('Произошла ошибка. Попробуйте снова');

      return { ...data };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getAllTelegramUsersReducerPending = (state: IUsersSlice) => {
  state.telegram.isLoading = true;
};
export const getAllTelegramUsersReducerFulfilled = (state: IUsersSlice, action: PayloadAction<IResponse>) => {
  state.telegram = { ...action.payload, isLoading: false };
};
export const getAllTelegramUsersReducerRejected = (state: IUsersSlice) => {
  state.telegram.isLoading = false;
};
