import { createSlice } from '@reduxjs/toolkit';

import {
  initFirstStartTipFulfilled,
  initFirstStartTipReducer,
  setFirstStartTipReducer,
  setFirstStartTipReducerFulfilled,
  setStartTipReducer,
  setStartTipReducerFulfilled,
} from './reducers/set-start-tip.reducer';

export interface ITipsSlice {
  startTip: boolean;
  firstStartTip: boolean;
}

const initialState: ITipsSlice = {
  startTip: false,
  firstStartTip: false,
};

const TipsSlice = createSlice({
  name: 'tips',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(initFirstStartTipReducer.fulfilled, initFirstStartTipFulfilled);
    builder.addCase(setFirstStartTipReducer.fulfilled, setFirstStartTipReducerFulfilled);
    builder.addCase(setStartTipReducer.fulfilled, setStartTipReducerFulfilled);
  },
});

export default TipsSlice;
