import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import CloseSVG from 'common/svg/close.svg';
import TrueFillCheckMarkSvg from 'common/svg/true-fill-check-mark.svg';
import FalseFillCheckMarkSvg from 'common/svg/false-fill-check-mark.svg';

import Button from 'common/components/button/button.component';

import { IFailedTariffId, StartSubscription, ResumeSubscription } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';

import { getAvailableParseLimitReducer } from '../../../../../store/slices/available-parse-limit/reducers/get-available-parse-limit.reducer';
import { getPaymentInfoReducer } from '../../../../../store/slices/payments/reducers/get-payment-info.reducer';

import { useAppDispatch } from '../../../../../store/interface/store.interface';

import { tariffs } from '../../../../../common/helper/consts/tariffs';

import styles from './styles.module.scss';

interface Props {
  handleIsActive: () => void;

  failedTariffId: IFailedTariffId;
}

const PaymentErrorComponent = ({ handleIsActive, failedTariffId }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={handleIsActive}>
          <CloseSVG />
        </button>
      </div>

      <div className={styles.content}>
        <PaymentErrorInfo failedTariffId={failedTariffId} />

        <div className={styles.card}>
          <div className={styles.header}>
            <h1>{t('order.inactive', { limits: tariffs[failedTariffId.tariffId].limits })}</h1>
            <p>{t('order.dueTo')}</p>
          </div>
          <ul>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.tip1')}</p>
            </li>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.tip2')}</p>
            </li>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.tip3')}</p>
            </li>
            <li>
              <TrueFillCheckMarkSvg />
              <p>{t('order.tip4')}</p>
            </li>
          </ul>

          <PaymentErrorButtons failedTariffId={failedTariffId} handleIsActive={handleIsActive} />
        </div>
      </div>
    </div>
  );
};

const PaymentErrorInfo = ({ failedTariffId }: Omit<Props, 'handleIsActive'>) => {
  const { t } = useTranslation();

  return (
    <div className={styles.info}>
      <h1>{t('order.paymentError')}</h1>
      {failedTariffId.reason === 'noValidCard' ? (
        <>
          <ul>
            <li>
              <FalseFillCheckMarkSvg />
              <p>{t('order.paymentError1')}</p>
            </li>
          </ul>
          <p>{t('order.paymentError2')}</p>
        </>
      ) : (
        <>
          <p>{t('order.paymentError3')}</p>
          <ul>
            <li>
              <TrueFillCheckMarkSvg />
              <p>{t('order.paymentError4')}</p>
            </li>
            <li>
              <TrueFillCheckMarkSvg />
              <p>{t('order.paymentError5')}</p>
            </li>
          </ul>
          <p>{t('order.paymentError6')}</p>
        </>
      )}
    </div>
  );
};

const PaymentErrorButtons = ({ failedTariffId, handleIsActive }: Props) => {
  const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handlePayAnotherTime = () => {
    setIsPaymentLoading(true);

    ResumeSubscription({ tariffId: failedTariffId.tariffId })
      .then((res) => {
        if (typeof res === 'string') {
          window.open(res, '_blank', 'noopener,noreferrer');
          handleIsActive();
        } else {
          if (res) {
            toast.success('Your subscription has been successfully resumed');
            handleIsActive();
          } else toast.error('Payment failed');
        }
      })
      .finally(() => {
        setIsPaymentLoading(false);

        Promise.all([
          dispatch(getAvailableParseLimitReducer({})),
          dispatch(getPaymentInfoReducer({})),
        ]);
      });
  };

  const handlePayWithAnotherCard = () =>
    StartSubscription({ tariffId: failedTariffId.tariffId })
      .then((res) => {
        if (res) window.open(res, '_blank', 'noopener,noreferrer');
        handleIsActive();
      })
      .catch(() => {
        toast.error('Payment error, please try later');
      });

  return (
    <div className={styles.footer}>
      {failedTariffId.reason === 'paymentError' && (
        <Button
          size="none"
          className={styles.button}
          onClick={handlePayAnotherTime}
          isLoading={isPaymentLoading}
          disabled={isPaymentLoading}>
          <p>{t('buttons.tryAgain')}</p>
        </Button>
      )}
      <Button
        size="none"
        className={styles.button}
        onClick={handlePayWithAnotherCard}
        isLoading={isPaymentLoading}
        disabled={isPaymentLoading}>
        <p>{t('buttons.addNewCard')}</p>
      </Button>
    </div>
  );
};

export default PaymentErrorComponent;
