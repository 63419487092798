import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { tariffs } from '../../../helper/consts/tariffs';
import ExpandLessSvg from '../../../svg/expand-less.svg';
import ExpandMoreSvg from '../../../svg/expand-more.svg';

import styles from './styles.module.scss';

interface Props {
  selectedTariffId: number;
  handleSelectedTariffId: (tariffId: number) => void;
  forcedLanguage?: 'ru' | 'en';
}

const TariffSelectComponent = ({ selectedTariffId, handleSelectedTariffId, forcedLanguage }: Props) => {
  const { t } = useTranslation();

  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);

  const handleIsSelectOpen = () => setIsSelectOpen((prev) => !prev);

  const handleTariffOption = (selectedTariffId: number) => {
    handleSelectedTariffId(selectedTariffId);
    handleIsSelectOpen();
  };

  return (
    <div className={styles.container}>
      <div className={styles.select} onClick={handleIsSelectOpen}>
        <p>{t('tarrif.limitMonth', { limit: tariffs[selectedTariffId].limits, lng: forcedLanguage })}</p>
        {isSelectOpen ? <ExpandLessSvg /> : <ExpandMoreSvg />}
      </div>
      {isSelectOpen && (
        <div className={styles.options}>
          {tariffs.map((tariff) => (
            <div className={styles.option} key={tariff.id} onClick={() => handleTariffOption(tariff.id)}>
              <p>{t('tarrif.limitMonth', { limit: tariff.limits, lng: forcedLanguage })}</p>
              {tariff.id > 0 && <span className={styles.mark}>-{tariff.discountPercent}%</span>}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TariffSelectComponent;
