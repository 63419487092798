import React, { useEffect } from 'react';

const MetricComponent = () => {
  const yandexMetrikaId = process.env.REACT_APP_YANDEX_METRIKA_ID ?? 98776726;

  // yandex metrika
  useEffect(() => {
    (function (m, e, t, r, i, k, a) {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (m[i] = m[i] || function () { (m[i].a = m[i].a || []).push(arguments); }),
      // @ts-ignore
      (m[i].l = 1 * new Date());
      for (let j = 0; j < document.scripts.length; j++) {
        if (document.scripts[j].src === r) return;
      }
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      k = e.createElement(t),
      // @ts-ignore
      a = e.getElementsByTagName(t)[0],
      // @ts-ignore
      (k.async = 1),
      // @ts-ignore
      (k.src = r),
      // @ts-ignore
      a.parentNode?.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    // @ts-ignore
    window.ym(Number(yandexMetrikaId), 'init', {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    });
  }, [yandexMetrikaId]);


  //hotjar
  useEffect(() => {
    if (!!process.env.REACT_APP_SENTRY_DSN) {
      (function(h, o, t, j, a, r) {
      // @ts-ignore
        h.hj =
        // @ts-ignore
        h.hj ||
        function() {
          // @ts-ignore
          (h.hj.q = h.hj.q || []).push(arguments);
        };
        // @ts-ignore
        h._hjSettings = { hjid: 5199766, hjsv: 6 };
        // @ts-ignore
        a = o.getElementsByTagName('head')[0];
        // @ts-ignore
        r = o.createElement('script');
        // @ts-ignore
        r.async = 1;
        // @ts-ignore
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        // @ts-ignore
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }
  }, []);

  return (
    <noscript>
      <div>
        <img
          src={`https://mc.yandex.ru/watch/${yandexMetrikaId}`}
          style={{ position: 'absolute', left: '-9999px' }}
          alt=""
        />
      </div>
    </noscript>
  );
};

export default MetricComponent;
