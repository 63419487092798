import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ECandidateToLinkedInUser,
  СreateIfNewCandidateToLinkedInUser,
  UpdateVacancyToCandidateRelationByIds,
  ECandidateStatus,
} from '@EZenkof/common-recruiter-library';

import { IValidateTechnologySlice } from '../vatidate.slice';

interface IChangeCandidateStatus {
  status: ECandidateStatus;
  recruitingVacancyId?: number;
  responsibleUserId?: string;
  technologyId?: number;
  candidateId: number;
}

export const changeCandidateStatus = createAsyncThunk<IChangeCandidateStatus, IChangeCandidateStatus>(
  'validateTechnologySlice/changeCandidateStatus',
  async ({ status, recruitingVacancyId, technologyId, candidateId, responsibleUserId }, { rejectWithValue }) => {
    try {
      if (!recruitingVacancyId && !technologyId) return rejectWithValue('Переданы неверные параметры');
      if (recruitingVacancyId) {
        await UpdateVacancyToCandidateRelationByIds({
          recruitingVacancyId,
          candidateId,
          candidateStatus: status,
        });
      }

      if (responsibleUserId && status === ECandidateStatus.approved)
        await СreateIfNewCandidateToLinkedInUser({
          candidateId,
          linkedInUserId: responsibleUserId,
          status: ECandidateToLinkedInUser.needToAdd,
        });

      return { status, recruitingVacancyId, technologyId, candidateId };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const changeCandidateStatusPending = (state: IValidateTechnologySlice) => {};
export const changeCandidateStatusFulfilled = (
  state: IValidateTechnologySlice,
  action: PayloadAction<IChangeCandidateStatus>,
) => {
  if (action.payload.status === ECandidateStatus.expected) state.count = state.count + 1;
  state.count = state.count - 1;
};
export const changeCandidateStatusRejected = (state: IValidateTechnologySlice) => {};
