import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteVacancyById } from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';


interface IDelete {
  id: number;
}

export const deleteVacancyReducer = createAsyncThunk<IDelete, IDelete>(
  'vacancies/deleteVacancy',
  async ({ id }, { rejectWithValue }) => {
    try {
      await DeleteVacancyById(id);
      return { id };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteVacancyReducerPending = (state: IVacanciesSlice) => {};
export const deleteVacancyReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<IDelete>) => {
  state.vacancies = state.vacancies.filter((v) => v.id !== action.payload.id);
  state.count = state.count - 1;
};
export const deleteVacancyReducerRejected = (state: IVacanciesSlice) => {};
