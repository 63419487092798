import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CreateTelegramUser, CreateTelegramUserDto, ITelegramUser } from '@EZenkof/common-recruiter-library';

import { IUsersSlice } from '../users.slice';

export const createTelegramUserReducer = createAsyncThunk<ITelegramUser, CreateTelegramUserDto>(
  'users/createTelegramUser',
  async (data, { rejectWithValue }) => {
    try {
      const user = await CreateTelegramUser(data);
      if (!user) throw new Error('Произошла ошибка. Попробуйте снова');

      return user;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const createTelegramUserPending = (state: IUsersSlice) => {
  state.telegram.isLoading = true;
};
export const createTelegramUserFulfilled = (state: IUsersSlice, action: PayloadAction<ITelegramUser>) => {
  state.telegram.users = [...state.telegram.users, action.payload];
  state.telegram.count = state.telegram.count + 1;
  state.telegram.isLoading = false;
};
export const createTelegramUserRejected = (state: IUsersSlice) => {
  state.telegram.isLoading = false;
};
