import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetVacancies,
  GetVacanciesCount,
  IRecruitingVacancy,
  IFilterRecruitingVacanciesDto,
} from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface IFilter {
  vacancies: IRecruitingVacancy[];
  count: number;
}

export const filterVacanciesReducer = createAsyncThunk<IFilter, { data: IFilterRecruitingVacanciesDto }>(
  'vacancies/filterVacancies',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await GetVacancies(data);
      const count = await GetVacanciesCount({});

      return { vacancies: res ?? [], count: count ?? 0 };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterVacanciesReducerPending = (state: IVacanciesSlice) => {
  state.isLoading = true;
};
export const filterVacanciesReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<IFilter>) => {
  state.vacancies = action.payload.vacancies;
  state.count = action.payload.count;
  state.isLoading = false;
};
export const filterVacanciesReducerRejected = (state: IVacanciesSlice) => {
  state.isLoading = false;
};
