import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GetVacancies, IRecruitingVacancy } from '@EZenkof/common-recruiter-library';

import { IVacanciesSlice } from '../vacancies.slice';

interface IResponse {
  vacancies: IRecruitingVacancy[];
}

interface IRequest {
  joinPlans?: boolean;
  joinPlanStats: boolean;
}

export const getVacanciesReducer = createAsyncThunk<IResponse, IRequest>(
  'vacancies/getVacancies',
  async ({ joinPlans, joinPlanStats }, { rejectWithValue }) => {
    try {
      const vacancies = await GetVacancies({ joinPlans, joinPlanStats });
      if (!vacancies) throw new Error('Произошла ошибка. Попробуйте снова');

      return { vacancies };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getVacanciesReducerPending = (state: IVacanciesSlice) => {
  state.isLoading = true;
};
export const getVacanciesReducerFulfilled = (state: IVacanciesSlice, action: PayloadAction<IResponse>) => {
  state.vacancies = action.payload.vacancies;
  state.isLoading = false;
};
export const getVacanciesReducerRejected = (state: IVacanciesSlice) => {
  state.isLoading = false;
};
