import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import TrueFillCheckMarkSvg from '../../../svg/true-fill-check-mark.svg';
import { tariffs } from '../../../helper/consts/tariffs';

import styles from './styles.module.scss';

interface Props {
  selectedTariff: number;
  forcedLanguage?: 'ru' | 'en';
}

const TariffOptionsComponent = ({ selectedTariff, forcedLanguage }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <p>
        <b>{t('tarrif.header', {lng: forcedLanguage})}</b>
      </p>
      <ul>
        <li>
          <TrueFillCheckMarkSvg />
          <p>{t('tarrif.limit', { limit: tariffs[selectedTariff].limits, lng: forcedLanguage })}</p>
        </li>
        <li>
          <TrueFillCheckMarkSvg />
          <p>{t('tarrif.automacly', {lng: forcedLanguage})}</p>
        </li>
        <li>
          <TrueFillCheckMarkSvg />
          <p>{t('tarrif.monitoring', {lng: forcedLanguage})}</p>
          <div className={styles.question}>
            <span>?</span>
            <p className={styles.modal}>{t('payments.updateTariffModal.question.monitoring', {lng: forcedLanguage})}</p>
          </div>
        </li>
        <li>
          <TrueFillCheckMarkSvg />
          <p>{t('tarrif.bypass', {lng: forcedLanguage})}</p>
          <div className={styles.question}>
            <span>?</span>
            <p className={styles.modal}>
              <Trans
                i18nKey={t('payments.updateTariffModal.question.bypass', {lng: forcedLanguage})}
                components={{ br: <br /> }}
              />
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default TariffOptionsComponent;
