import { GetCandidates, GetCandidatesCount, ICandidate, IFilterCandidatesDto } from '@EZenkof/common-recruiter-library';
import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IValidateTechnologyConfirmSlice } from '../vatidate-confirm.slice';

interface IFilter {
  candidates: ICandidate[];
  count: number;
}

export const filterValidateCandidates = createAsyncThunk<IFilter, { data: IFilterCandidatesDto }>(
  'validateTechnologyConfirmSlice/filterValidateCandidates',
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await GetCandidates(data);
      const count = await GetCandidatesCount(data);

      return { candidates: res ?? [], count: count ?? 0 };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const filterValidateCandidatesPending = (state: IValidateTechnologyConfirmSlice) => {
  state.isLoading = true;
};
export const filterValidateCandidatesFulfilled = (
  state: IValidateTechnologyConfirmSlice,
  action: PayloadAction<IFilter>,
) => {
  state.candidates = action.payload.candidates;
  state.count = action.payload.count;
  state.isLoading = false;
};
export const filterValidateCandidatesRejected = (state: IValidateTechnologyConfirmSlice) => {
  state.isLoading = false;
};
