import Modal from 'common/components/modal/modal.component';
import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { StartSubscription } from '@EZenkof/common-recruiter-library';

import { toast } from 'react-toastify';

import Button from '../../../components/button/button.component';

import { useAppDispatch, useAppSelector } from '../../../../store/interface/store.interface';

import { setTrialModalOpenReducer } from '../../../../store/slices/payments/payments.slice';

import CloseSVG from '../../../svg/close.svg';

import { useLocalStorage } from '../../../helper/hooks/use-local-storage.hook';

import styles from './styles.module.scss';

import TariffInfoComponent from './components/tariff-info/tariff-info.component';

const TrialModal = () => {
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [selectedTariffId, setSelectedTariffId] = useState<number>(0);

  const {
    info: { trialEndDate },
    trialModal,
  } = useAppSelector((state) => state.payments);
  const dispatch = useAppDispatch();

  const [sessionsStatistic, setSessionsStatistic] = useLocalStorage<{
    lastSessionDate: number;
    session: number;
    isCollectedInitialFeedback?: boolean;
    isCollectedFinalFeedback?: boolean;
    isShowedTrialEndModal?: boolean;
  }>('sessionsStatistic', {
    lastSessionDate: Date.now(),
    session: 1,
    isCollectedInitialFeedback: false,
    isCollectedFinalFeedback: false,
    isShowedTrialEndModal: false,
  });

  const { t } = useTranslation();

  const handleCloseModal = () => {
    dispatch(setTrialModalOpenReducer({ isOpen: false }));
    setSessionsStatistic({
      ...sessionsStatistic,
      session: sessionsStatistic?.session ?? 1,
      lastSessionDate: sessionsStatistic?.lastSessionDate ?? Date.now(),
      isShowedTrialEndModal: true,
    });
  };

  const handleSelectedTariffId = (tariffId: number) => setSelectedTariffId(tariffId);

  const handleStartSubscription = () => {
    setIsUpdateLoading(true);

    StartSubscription({ tariffId: selectedTariffId })
      .then((res) => {
        if (res) window.open(res, '_blank', 'noopener,noreferrer');
      })
      .catch(() => {
        toast.error('Payment error, please try later');
      })
      .finally(() => setIsUpdateLoading(false));
  };

  useEffect(() => {
    if (!sessionsStatistic?.isShowedTrialEndModal && trialEndDate)
      dispatch(
        setTrialModalOpenReducer({
          isOpen: trialEndDate <= Date.now(),
          type: 'end',
        }),
      );
  }, [trialEndDate]);

  return (
    <Modal active={trialModal.isOpen && !!trialModal.type} setActive={handleCloseModal}>
      <div className={styles.container}>
        <div className={styles.header}>
          <button onClick={handleCloseModal}>
            <CloseSVG />
          </button>
        </div>

        <div className={styles.content}>
          <div className={styles.info}>
            <h1>{t(`payments.trialModal.${trialModal.type}.header`)}</h1>
            <p>{t(`payments.trialModal.${trialModal.type}.tip`)}</p>
          </div>

          <div className={styles.card}>
            <TariffInfoComponent selectedTariffId={selectedTariffId} handleSelectedTariffId={handleSelectedTariffId} />

            <div className={styles.footer}>
              <Button
                size="none"
                className={styles.button}
                onClick={handleStartSubscription}
                isLoading={isUpdateLoading}
                disabled={isUpdateLoading}>
                <p>{t('buttons.connect')}</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TrialModal;
