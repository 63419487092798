import React from 'react';

import { useTranslation } from 'react-i18next';

import { tariffs } from '../../../../../helper/consts/tariffs';

import TariffSelectComponent from '../../../../../components/tariff/tariff-select/tariff-select.component';
import TariffOptionsComponent from '../../../../../components/tariff/tariff-options/tariff-options.component';

import styles from './styles.module.scss';

interface Props {
  selectedTariffId: number;
  handleSelectedTariffId: (tariffId: number) => void;
  forcedLanguage?: 'ru' | 'en';
}

const TariffInfoComponent = ({ selectedTariffId, handleSelectedTariffId, forcedLanguage }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.tariff}>
        <div className={styles.title}>
          <h1>{t('tarrif.choose', {lng: forcedLanguage})}</h1>
          <TariffSelectComponent
            selectedTariffId={selectedTariffId}
            handleSelectedTariffId={handleSelectedTariffId}
            forcedLanguage={forcedLanguage}
          />
        </div>

        <div className={styles.price}>
          <h1>
            {tariffs[selectedTariffId].finalPrice}$<span>{t('common.perMonth', {lng: forcedLanguage})}</span>
          </h1>
          <p>
            {t('common.1000Limits', {lng: forcedLanguage})} {tariffs[selectedTariffId].pricePerThousand}$
          </p>
        </div>
      </div>

      <TariffOptionsComponent selectedTariff={selectedTariffId} forcedLanguage={forcedLanguage}/>
    </>
  );
};

export default TariffInfoComponent;
