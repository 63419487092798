import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import StartTipModal from './support.modal';

import styles from './styles.module.scss';

interface Props {
  active: boolean;
  setActive: () => void;
}

const StartTipClickModal = ({ active, setActive }: Props) => {
  const { t } = useTranslation();
  const handleClose = () => {
    setActive();
  };

  return (
    <StartTipModal active={active} setActive={handleClose}>
      <h1>
        <Trans i18nKey={t('startModal.start')} />
      </h1>
    </StartTipModal>
  );
};

export default StartTipClickModal;
