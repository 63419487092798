export const ROUTES = {
  INDEX: {
    INDEX: '/',
    PRICING: '/pricing',
    LOGIN: '/login',
    REGISTRATION: '/registration',
    ACTIVATE: '/activate',
    RECOVERY: '/recovery',
    ALL: '/*',
  },

  PROFILE: {
    INDEX: '/profile',
    CANDIDATES: 'candidates',
    PAYMENTS: 'payments',
    PRO: {
      CONNECTION_LIST: '/profile/connectionAcceptedList',
      VACANCIES: '/profile/vacancies/all',
      MANAGE_USERS: '/profile/manage-users',
    },
  },
};
