import React from 'react';

const LogoutSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="black">
      <path
        d="M232-172q-26 0-43-17t-17-43v-496q0-26 17-43t43-17h249v28H232q-12 0-22 10t-10 22v496q0 12 10 22t22 10h249v28H232Zm432-184-20-20 90-90H370v-28h364l-90-90 20-20 124 124-124 124Z" />
    </svg>
  );
};

export default LogoutSvg;
