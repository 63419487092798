import React, { lazy, useEffect } from 'react';

import { Routes, Route } from 'react-router-dom';

import MainLayout from 'layout/main.layout';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from 'common/helper/hooks/useAuth';
import { ProtectedLayout } from 'layout/protected/protected.latout';
import { ProProtectedLayout } from 'layout/protected/pro-protected.latout';
import FirstStartTipModal from 'common/modal/start-tip/support-start.modal';
import { useAppDispatch, useAppSelector } from 'store/interface/store.interface';
import { setFirstStartTipReducer, setStartTipReducer } from 'store/slices/tips/reducers/set-start-tip.reducer';
import TrialModal from 'common/modal/payments/trial/trial.modal';
import StartTipClickModal from 'common/modal/start-tip/support-click.modal';
import FeedbackFrameModal from 'common/modal/feedback-frame/feedback-frame.modal';
import i18n from 'i18n';
import { ROUTES } from 'common/helper/consts/routes';
import * as Sentry from '@sentry/react';

import MetricComponent from './common/components/metric/metric.component';

const PaymentsPage = lazy(() => import('./pages/payments/payments.page'));
const Pricing = lazy(() => import('./pages/pricing/pricing.page'));
const About = lazy(() => import('./pages/about/about.page'));
const RecoveryPage = lazy(() => import('./pages/recovery/recovery.page'));
const ActivatePage = lazy(() => import('pages/activate/activate.page'));
const RegistrationPage = lazy(() => import('pages/registration/registration.page'));
const LoginPage = lazy(() => import('pages/login/login.page'));
const ConnectionAcceptedCandidatesList = lazy(() => import('pages/connection-list/connection-accepted-list.page'));
const ValidatePage = lazy(() => import('pages/home/validate.page'));
const Vacancies = lazy(() => import('pages/vacancies/vacancies.page'));
const AllVacancies = lazy(() => import('pages/all-vacancies/all-vacancies.page'));
const ManageUsersPage = lazy(() => import('pages/manage-users/manage-users.page'));

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN ??
    'https://94f9c00aaa7f06a20c2cd35b1316de5e@o4508259347660800.ingest.us.sentry.io/4508259349168128',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  maxBreadcrumbs: 50, // по дефолту 100
  attachStacktrace: true,
  tracesSampleRate: 1.0,
  tracePropagationTargets: [/.*/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = () => {
  const dispatch = useAppDispatch();
  const { startTip, firstStartTip } = useAppSelector((state) => state.tips);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    i18n.changeLanguage(savedLanguage || 'en');
  }, []);

  const handleFirstStartTip = () => {
    dispatch(setFirstStartTipReducer(!firstStartTip));
  };

  const handleStartTip = () => {
    dispatch(setStartTipReducer(!startTip));
  };

  return (
    <>
      <MetricComponent/>
      <TrialModal />
      <FeedbackFrameModal />
      <FirstStartTipModal active={firstStartTip} setActive={handleFirstStartTip} />
      <StartTipClickModal active={startTip} setActive={handleStartTip} />
      <AuthProvider>
        <Routes>
          <Route path={ROUTES.INDEX.INDEX} element={<MainLayout />}>
            <Route index element={<About />} />
            <Route path={ROUTES.INDEX.PRICING} element={<Pricing />} />
            <Route path={ROUTES.INDEX.LOGIN} element={<LoginPage />} />
            <Route path={ROUTES.INDEX.REGISTRATION} element={<RegistrationPage />} />
            <Route path={ROUTES.INDEX.ACTIVATE} element={<ActivatePage />} />
            <Route path={ROUTES.INDEX.RECOVERY} element={<RecoveryPage />} />
            <Route path={ROUTES.INDEX.ALL} element={<p>Not found</p>} />

            <Route path={ROUTES.PROFILE.INDEX} element={<ProtectedLayout />}>
              <Route index element={<Vacancies />} />
              <Route path={ROUTES.PROFILE.CANDIDATES} element={<ValidatePage />} />
              <Route path={ROUTES.PROFILE.PAYMENTS} element={<PaymentsPage />} />
              <Route path="" element={<ProProtectedLayout />}>
                <Route path={ROUTES.PROFILE.PRO.CONNECTION_LIST} element={<ConnectionAcceptedCandidatesList />} />
                <Route path={ROUTES.PROFILE.PRO.VACANCIES} element={<AllVacancies />} />
                <Route path={ROUTES.PROFILE.PRO.MANAGE_USERS} element={<ManageUsersPage />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AuthProvider>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
