import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getAvailableParseLimitReducer,
  getAvailableParseLimitReducerFulfilled,
  getAvailableParseLimitReducerPending,
  getAvailableParseLimitReducerRejected,
} from './reducers/get-available-parse-limit.reducer';

export interface IAvailableParseLimitSlice {
  limit: number | null;
  isLoading: boolean;
}

const initialState: IAvailableParseLimitSlice = {
  limit: null,
  isLoading: false,
};

const availableParseLimitSlice = createSlice({
  name: 'availableParseLimit',
  initialState: initialState,
  reducers: {
    updateAvailableParseLimitReducer: (state, action: PayloadAction<Pick<IAvailableParseLimitSlice, 'limit'>>) => {
      state.limit = action.payload.limit;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAvailableParseLimitReducer.pending, getAvailableParseLimitReducerPending)
      .addCase(getAvailableParseLimitReducer.fulfilled, getAvailableParseLimitReducerFulfilled)
      .addCase(getAvailableParseLimitReducer.rejected, getAvailableParseLimitReducerRejected);
  },
});

export const { updateAvailableParseLimitReducer } = availableParseLimitSlice.actions;

export default availableParseLimitSlice;
