import * as React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import ExpandMoreSvg from 'common/svg/expand-more.svg';
import { useAuth } from 'common/helper/hooks/useAuth';
import LogoutSvg from 'common/svg/logout.svg';

import { useEffect, useState, useRef } from 'react';

import useClickOutside from 'common/helper/hooks/use-click-outside.hook';

import AccountSvg from 'common/svg/account.svg';
import MoneySvg from 'common/svg/money.svg';

import { useTranslation } from 'react-i18next';

import i18n from 'i18n';

import { getAvailableParseLimitReducer } from '../../../store/slices/available-parse-limit/reducers/get-available-parse-limit.reducer';

import { useAppDispatch, useAppSelector } from '../../../store/interface/store.interface';

import { formatNumberUtil } from '../../helper/utils/format-number.util';

import { tariffs } from '../../helper/consts/tariffs';

import styles from './header.module.scss';

interface IPage {
  name: string;
  id: number;
  path: string;
  target?: string;
  mode?: 'pro' | 'unauthorized';
  children?: {
    name: string;
    id: number;
    path: string;
  }[];
}

const pages = (t: any): IPage[] => [
  {
    name: t('header.vacancies'),
    id: 1,
    path: '/profile',
  },
  {
    name: t('header.candidates'),
    id: 4,
    path: '/profile/candidates',
  },
  {
    name: t('header.candidateStatus'),
    id: 2,
    path: '/profile/connectionAcceptedList',
    mode: 'pro',
  },
  {
    name: t('header.analytics'),
    id: 6,
    path: '/profile/vacancies/all',
    mode: 'pro',
  },
  {
    name: t('header.manageUsers'),
    id: 7,
    path: '/profile/manage-users',
    mode: 'pro',
  },
  {
    name: 'Обзор',
    id: 8,
    path: '/',
    mode: 'unauthorized',
  },
  {
    name: 'Тарифы',
    id: 9,
    path: '/pricing',
    mode: 'unauthorized',
  },
  {
    name: 'Wiki',
    id: 10,
    path: `${process.env.REACT_APP_WIKI_URL}`,
    mode: 'unauthorized',
    target: '_blank',
  },
  {
    name: 'Аккаунт',
    id: 11,
    path: '/login',
    mode: 'unauthorized',
  },
];

const getPages = (currentPath: string,  t: any, mode?: 'pro' | 'light') => {
  return pages(t)
    .filter((p) => {
      if (currentPath.includes('profile')) {
        if (p.mode === 'unauthorized') return null;
        if (!mode) return null;
        if (mode === 'pro') return p;
        if (p.mode !== 'pro') return p;
        return null;
      } else {
        if (p.mode === 'unauthorized') return p;
        return null;
      }
    })
    .map((page) => (
      <li key={page.id}>
        <NavLink className={`${styles.headerItem}`} to={page.path} target={page.target}>
          <div className={styles.expand}>
            <p>{page.name}</p>
            {page.children && <ExpandMoreSvg />}
          </div>
          {!page.children && <span className={styles.border}></span>}
        </NavLink>
        {page.children && (
          <div className={styles.children}>
            {page.children.map((child) => (
              <NavLink to={child.path} key={child.id} className={styles.child}>
                <p>{child.name}</p>
              </NavLink>
            ))}
          </div>
        )}
        {currentPath === page.path && <span className={styles.active__border}></span>}
      </li>
    ));
};

export const Header = () => {
  const { logout, user } = useAuth();
  const location = useLocation();

  const { t } = useTranslation();

  const [currentPath, setCurrentPath] = React.useState(location.pathname);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [isLngOpen, setIsLngOpen] = useState<boolean>(false);

  const { info } = useAppSelector((state) => state.payments);
  const { limit: availableParseLimit } = useAppSelector((state) => state.availableParseLimitSlice);

  const dispatch = useAppDispatch();

  const profileListStyle = currentPath.includes('profile')
    ? { justifyContent: 'center' }
    : { paddingRight: '35px' };

  const handleLngOpen = () => {
    setIsLngOpen((prev) => !prev);
  };

  const handlePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const getAvailableLimit = (availableParseLimit: number | null) =>
    typeof availableParseLimit === 'number' &&
    (typeof info.currentTariffId === 'number' || (info.trialEndDate ?? 0) > Date.now())
      ? `${formatNumberUtil(availableParseLimit)} / ${formatNumberUtil(tariffs[info.currentTariffId ?? 0].limits)}`
      : t('common.unavaliable');

  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <>
      <header className={styles.container}>
        <Logo />
        <div className={styles.list} style={profileListStyle}>
          <ul className={styles.navigation}>{getPages(currentPath, t, user?.mode)}</ul>
        </div>
        {user && currentPath.includes('profile') && (
          <div className={styles.profile}>
            <div className={styles.limits}>
              <p>{t('header.limits')}</p>
              <p>{getAvailableLimit(availableParseLimit)}</p>
            </div>
            <button onClick={handlePopup}>
              <AccountSvg />
            </button>
          </div>
        )}
        {location.pathname !== '/' && location.pathname !== '/pricing' && (
          <div className={styles.lng}>
            <button onClick={handleLngOpen}>
              <p>{i18n.language.toUpperCase()}</p>
            </button>
            <PopupLng isOpen={isLngOpen} handleOpen={handleLngOpen} />
          </div>
        )}
      </header>
      <Popup isPopupOpen={isPopupOpen} handlePopup={handlePopup} logout={logout} />
    </>
  );
};

export const Logo = () => {
  return (
    <NavLink to="/" className={`${styles.headerItem} ${styles.logo}`}>
      <p>Hyranse</p>
    </NavLink>
  );
};

interface IAccountPopupProps {
  isPopupOpen: boolean;
  handlePopup: () => void;

  logout: () => void;
}

const Popup = ({ isPopupOpen, handlePopup, logout }: IAccountPopupProps) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const ref = useRef(null);
  useClickOutside(ref, handlePopup, isPopupOpen);

  const className = `${styles.popup} ${isPopupOpen ? styles.show : ''}`;

  const handleLogout = () => {
    localStorage.removeItem('sessionsStatistic');
    logout();
    handlePopup();
  };

  const navigateToPayments = () => {
    navigate('/profile/payments');
    handlePopup();
  };

  return (
    <div ref={ref} className={className}>
      <button title="Payments" className={styles.button} onClick={navigateToPayments}>
        <MoneySvg />
        <p>{t('header.profile.payments')}</p>
      </button>
      <button title={t('header.profile.logout')} className={styles.button} onClick={handleLogout}>
        <LogoutSvg />
        <p>{t('header.profile.logout')}</p>
      </button>
    </div>
  );
};

const PopupLng = ({ isOpen, handleOpen }: { isOpen: boolean; handleOpen: () => void }) => {
  const ref = useRef(null);
  useClickOutside(ref, handleOpen, isOpen);

  const handleChangeLng = (lng: string) => {
    localStorage.setItem('language', lng);
    i18n.changeLanguage(lng);
    handleOpen();
  };

  return (
    <div className={`${styles.lng_popup} ${isOpen && styles.lng_open}`}>
      {['ru', 'en'].map((lng) => (
        <button onClick={() => handleChangeLng(lng)} key={lng}>
          {lng.toUpperCase()}
        </button>
      ))}
    </div>
  );
};

export default Header;
