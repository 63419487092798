import { GetNewVacancyCandidatesCount } from '@EZenkof/common-recruiter-library';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';

import { IValidateTechnologySlice } from '../vatidate.slice';

interface IGet {
  id: number;
}

interface IGetResult {
  notViewedCount: number | null;
}

export const getNotViewedCountReducer = createAsyncThunk<IGetResult, IGet>(
  'validateTechnologySlice/getNewVacancyCandidatesCount',
  async ({id}, {rejectWithValue}) => {
    try {
      const res = await GetNewVacancyCandidatesCount(id);
      return {notViewedCount: res};
    }catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const getNotViewedCountReducerPending = (state: IValidateTechnologySlice) => {};
export const getNotViewedCountReducerFulfilled = (state: IValidateTechnologySlice, action: PayloadAction<IGetResult>) => {
  state.notViewedCount = action.payload.notViewedCount;
};
export const getNotViewedCountReducerRejected = (state: IValidateTechnologySlice) => {};
