import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DeleteTelegramUserToAccountGroup } from '@EZenkof/common-recruiter-library';

import { IUsersSlice } from '../users.slice';

export const deleteTelegramUserReducer = createAsyncThunk<number, number>(
  'users/deleteTelegramUser',
  async (id, { rejectWithValue }) => {
    try {
      await DeleteTelegramUserToAccountGroup(id);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  },
);

export const deleteTelegramUserPending = (state: IUsersSlice) => {
  state.telegram.isLoading = true;
};
export const deleteTelegramUserFulfilled = (state: IUsersSlice, action: PayloadAction<number>) => {
  state.telegram.users = state.telegram.users.filter((user) => user.telegramId !== action.payload);
  state.telegram.count = state.telegram.count - 1;
  state.telegram.isLoading = false;
};
export const deleteTelegramUserRejected = (state: IUsersSlice) => {
  state.telegram.isLoading = false;
};
