import { IPaymentInfo } from '@EZenkof/common-recruiter-library';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getPaymentInfoReducer,
  getPaymentInfoReducerFulfilled,
  getPaymentInfoReducerPending, getPaymentInfoReducerRejected,
} from './reducers/get-payment-info.reducer';

export interface IPaymentsSlice {
  isLoading: boolean;
  trialModal: {
    isOpen: boolean,
    type?: 'subscribe' | 'end',
  },
  info: IPaymentInfo;
}

const initialState: IPaymentsSlice = {
  isLoading: false,
  trialModal: {
    isOpen: false,
  },
  info: {
    tax: 0,
    paymentCards: [],
    orders: [],
  },
};

const PaymentsSlice = createSlice({
  name: 'paymentsSlice',
  initialState: initialState,
  reducers: {
    setTrialModalOpenReducer(state, action: PayloadAction<{isOpen: boolean, type?: 'subscribe' | 'end'}>){
      state.trialModal = {
        isOpen: action.payload.isOpen,
        type: action.payload.type,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getPaymentInfoReducer.pending, getPaymentInfoReducerPending)
      .addCase(getPaymentInfoReducer.fulfilled, getPaymentInfoReducerFulfilled)
      .addCase(getPaymentInfoReducer.rejected, getPaymentInfoReducerRejected);
  },
});

export const {setTrialModalOpenReducer} = PaymentsSlice.actions;

export default PaymentsSlice;
