import React, { createContext, useContext, useMemo, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser } from '@EZenkof/common-recruiter-library';
import { initFirstStartTipReducer } from 'store/slices/tips/reducers/set-start-tip.reducer';
import { useAppDispatch } from 'store/interface/store.interface';

import { useLocalStorage } from './use-local-storage.hook';

// Определяем тип контекста аутентификации
interface AuthContextType {
  user: IUser | null;
  login: (data: IUser) => void;
  logout: () => void;
}

// Создаем контекст аутентификации
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Создаем провайдер аутентификации
export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useLocalStorage<IUser | null>('canbanUser', null);
  const navigate = useNavigate();
  const dispacth = useAppDispatch();

  // Функция для входа пользователя
  const login = async (data: IUser) => {
    setUser(data);
    navigate('/profile');
    dispacth(initFirstStartTipReducer());
  };

  // Функция для выхода пользователя
  const logout = () => {
    setUser(null);
    navigate('/', { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
