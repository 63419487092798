import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { GetCandidates, GetCandidatesCount, ICandidate, IFilterCandidatesDto } from '@EZenkof/common-recruiter-library';

import { IConnectionAcceptedSlice } from '../connection-list.slice';

export const fetchCandidatesReducer = createAsyncThunk<
  { candidates: ICandidate[]; count: number },
  { data: IFilterCandidatesDto }
>('example/getExamples', async ({ data }, { rejectWithValue }) => {
  try {
    const candidates = await GetCandidates(data);
    const count = await GetCandidatesCount(data);

    return { candidates: candidates ?? [], count: count ?? 0 };
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const fetchCandidatesPending = (state: IConnectionAcceptedSlice) => {
  state.isLoading = true;
};
export const fetchCandidatesFulfilled = (
  state: IConnectionAcceptedSlice,
  action: PayloadAction<{ candidates: ICandidate[]; count: number }>,
) => {
  state.candidates = action.payload.candidates;
  state.count = action.payload.count;
  state.isLoading = false;
};
export const fetchCandidatesRejected = (state: IConnectionAcceptedSlice, action: any) => {
  state.isLoading = false;
};
