import { createSlice } from '@reduxjs/toolkit';
import { ILinkedInUser, ITelegramUser } from '@EZenkof/common-recruiter-library';

import {
  getAllTelegramUsersReducer,
  getAllTelegramUsersReducerFulfilled,
  getAllTelegramUsersReducerPending,
  getAllTelegramUsersReducerRejected,
} from './reducers/get-all-telegram-users.reducer';
import {
  createTelegramUserFulfilled,
  createTelegramUserPending,
  createTelegramUserReducer,
  createTelegramUserRejected,
} from './reducers/create-telegram-user.reducer';
import {
  deleteTelegramUserFulfilled,
  deleteTelegramUserPending,
  deleteTelegramUserReducer,
  deleteTelegramUserRejected,
} from './reducers/delete-telegram-user.reducer';
import {
  getAllLinkedinUsersReducer,
  getAllLinkedinUsersReducerFulfilled,
  getAllLinkedinUsersReducerPending,
  getAllLinkedinUsersReducerRejected,
} from './reducers/get-all-linkedIn-users.reducer';
import {
  deleteLinkedInUserFulfilled,
  deleteLinkedInUserPending,
  deleteLinkedInUserReducer,
  deleteLinkedInUserRejected,
} from './reducers/delete-linkedIn-user.reducer';

interface IUserItem<T> {
  users: T;
  count: number;
  isLoading: boolean;
}

export interface IUsersSlice {
  telegram: IUserItem<ITelegramUser[]>;
  linkedIn: IUserItem<ILinkedInUser[]>;
}

const initialState: IUsersSlice = {
  telegram: {
    users: [],
    count: 0,
    isLoading: false,
  },
  linkedIn: {
    users: [],
    count: 0,
    isLoading: false,
  },
};

const UsersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllTelegramUsersReducer.pending, getAllTelegramUsersReducerPending)
      .addCase(getAllTelegramUsersReducer.fulfilled, getAllTelegramUsersReducerFulfilled)
      .addCase(getAllTelegramUsersReducer.rejected, getAllTelegramUsersReducerRejected)

      .addCase(deleteTelegramUserReducer.pending, deleteTelegramUserPending)
      .addCase(deleteTelegramUserReducer.fulfilled, deleteTelegramUserFulfilled)
      .addCase(deleteTelegramUserReducer.rejected, deleteTelegramUserRejected)

      .addCase(createTelegramUserReducer.pending, createTelegramUserPending)
      .addCase(createTelegramUserReducer.fulfilled, createTelegramUserFulfilled)
      .addCase(createTelegramUserReducer.rejected, createTelegramUserRejected)

      .addCase(deleteLinkedInUserReducer.pending, deleteLinkedInUserPending)
      .addCase(deleteLinkedInUserReducer.fulfilled, deleteLinkedInUserFulfilled)
      .addCase(deleteLinkedInUserReducer.rejected, deleteLinkedInUserRejected)

      .addCase(getAllLinkedinUsersReducer.pending, getAllLinkedinUsersReducerPending)
      .addCase(getAllLinkedinUsersReducer.fulfilled, getAllLinkedinUsersReducerFulfilled)
      .addCase(getAllLinkedinUsersReducer.rejected, getAllLinkedinUsersReducerRejected);
  },
});

export default UsersSlice;
