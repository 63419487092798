import React, { useRef, useState } from 'react';
import InfoSvg from 'common/svg/info.svg';
import { useAppDispatch } from 'store/interface/store.interface';
import { setStartTipReducer } from 'store/slices/tips/reducers/set-start-tip.reducer';
import { useLocation } from 'react-router-dom';
import useClickOutside from 'common/helper/hooks/use-click-outside.hook';
import { useTranslation } from 'react-i18next';

import QuestionSvg from '../../../common/svg/question.svg';
import LampSvg from '../../../common/svg/lamp.svg';
import DocsSvg from '../../../common/svg/docs.svg';
import SVGMail from '../../../common/svg/mail.svg';
import BugSvg from '../../../common/svg/bug.svg';

import { setFeedbackFrameType } from '../../../store/slices/feedback-frames/feedback-frames.slice';

import styles from './styles.module.scss';

const Tip = () => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = React.useState(location.pathname);
  const [active, setActive] = useState<boolean>(false);

  const { t } = useTranslation();

  const [isHelpClicked, setIsHelpClicked] = useState<boolean>(false);

  const handleActive = (): void => {
    setActive((s) => !s);
    setIsHelpClicked(false);
  };

  const ref = useRef(null);
  useClickOutside(ref, handleActive, active);

  React.useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  const dispatch = useAppDispatch();
  const handleStartTip = () => {
    dispatch(setStartTipReducer());
  };

  const handleHelpClick = () => setIsHelpClicked((prev) => !prev);

  const handleOpenBugReportFrame = () => {
    handleActive();
    dispatch(setFeedbackFrameType({ activeType: 'bugReport' }));
  };

  const handleOpenSuggestionFrame = () => dispatch(setFeedbackFrameType({ activeType: 'suggestion' }));

  const navigateToWiki = () => window.open(`${process.env.REACT_APP_WIKI_URL}`, '_blank', 'noopener,noreferrer');

  if (!currentPath.includes('profile')) {
    return <></>;
  }

  return (
    <>
      {active && (
        <div className={styles.modal} ref={ref}>
          <button className={styles.link} onClick={navigateToWiki}>
            <DocsSvg />
            <p>{t('popup.wiki')}</p>
          </button>
          <button className={styles.link} onClick={handleHelpClick}>
            <QuestionSvg />
            <p>{t('popup.help')}</p>
          </button>
          {isHelpClicked && (
            <div className={styles.additional}>
              <a className={styles.link} href="https://t.me/hyranse_support" target="_blank" rel="noreferrer">
                <SVGMail />
                <p>{t('popup.message')}</p>
              </a>
              <button className={styles.link} onClick={handleOpenBugReportFrame}>
                <BugSvg />
                <p>{t('popup.report')}</p>
              </button>
            </div>
          )}
          <button className={styles.link} onClick={handleStartTip}>
            <InfoSvg />
            <p>{t('popup.onboarding')}</p>
          </button>
          <button className={styles.link} onClick={handleOpenSuggestionFrame}>
            <LampSvg />
            <p>{t('popup.improvement')}</p>
          </button>
        </div>
      )}
      <button onClick={handleActive} className={styles.container}>
        ?
      </button>
    </>
  );
};

export default Tip;
