import { createSlice } from '@reduxjs/toolkit';
import { ICandidate } from '@EZenkof/common-recruiter-library';

import {
  fetchCandidatesReducer,
  fetchCandidatesFulfilled,
  fetchCandidatesPending,
  fetchCandidatesRejected,
} from './reducers/fetch-candiadtes.reducer';
import {
  updateCandidateReducer,
  updateCandidateFulfilled,
  updateCandidatePending,
  updateCandidateRejected,
} from './reducers/update-candidate.reducer';
import {
  updateCandidateToVacancy,
  updateCandidateToVacancyFulfilled,
  updateCandidateToVacancyPending,
  updateCandidateToVacancyRejected,
} from './reducers/update-candidate-to-vacancy.reducer';

export interface IConnectionAcceptedSlice {
  candidates: ICandidate[];
  count: number;
  isLoading: boolean;
  updateLoading: boolean;
  technologyLoading: boolean;
  vacancyLoading: boolean;
}

export const initialState: IConnectionAcceptedSlice = {
  candidates: [],
  count: 0,
  isLoading: false,
  updateLoading: false,
  technologyLoading: false,
  vacancyLoading: false,
};

const ConnectionAcceptedSlice = createSlice({
  name: 'exampleSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCandidatesReducer.pending, fetchCandidatesPending)
      .addCase(fetchCandidatesReducer.fulfilled, fetchCandidatesFulfilled)
      .addCase(fetchCandidatesReducer.rejected, fetchCandidatesRejected)

      .addCase(updateCandidateReducer.pending, updateCandidatePending)
      .addCase(updateCandidateReducer.fulfilled, updateCandidateFulfilled)
      .addCase(updateCandidateReducer.rejected, updateCandidateRejected)

      .addCase(updateCandidateToVacancy.pending, updateCandidateToVacancyPending)
      .addCase(updateCandidateToVacancy.fulfilled, updateCandidateToVacancyFulfilled)
      .addCase(updateCandidateToVacancy.rejected, updateCandidateToVacancyRejected);
  },
});

export default ConnectionAcceptedSlice;
