import { PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { IUpdateCandidateDto, UpdateCandidate } from '@EZenkof/common-recruiter-library';
import { toast } from 'react-toastify';

import { IConnectionAcceptedSlice } from '../connection-list.slice';

export const updateCandidateReducer = createAsyncThunk<
  { data: IUpdateCandidateDto; id: number },
  { data: IUpdateCandidateDto; id: number }
>('example/updateCandidate', async ({ id, data }, { rejectWithValue }) => {
  try {
    await UpdateCandidate(id, data);
    return { data, id };
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

export const updateCandidatePending = (state: IConnectionAcceptedSlice) => {
  state.updateLoading = true;
};
export const updateCandidateFulfilled = (
  state: IConnectionAcceptedSlice,
  action: PayloadAction<{ data: IUpdateCandidateDto; id: number }>,
) => {
  state.candidates = state.candidates.map((e: any) => {
    if (e.id === action.payload.id) {
      return { ...e, ...action.payload.data };
    }
    return e;
  });

  toast.success('Candidate has been updated');
  state.updateLoading = false;
};
export const updateCandidateRejected = (state: IConnectionAcceptedSlice) => {
  toast.error('Candidate has not been updated');
  state.updateLoading = false;
};
