export const tariffs = [
  {
    id: 0,
    limits: 10000,
    pricePerThousand: 1,
    finalPrice: 10,
    discountPercent: 0,
  },
  {
    id: 1,
    limits: 15000,
    pricePerThousand: 0.95,
    finalPrice: 14.25,
    discountPercent: 5,
  },
  {
    id: 2,
    limits: 25000,
    pricePerThousand: 0.9,
    finalPrice: 22.50,
    discountPercent: 10,
  },
  {
    id: 3,
    limits: 40000,
    pricePerThousand: 0.85,
    finalPrice: 34,
    discountPercent: 15,
  },
  {
    id: 4,
    limits: 60000,
    pricePerThousand: 0.8,
    finalPrice: 48,
    discountPercent: 20,
  },
  {
    id: 5,
    limits: 100000,
    pricePerThousand: 0.75,
    finalPrice: 75,
    discountPercent: 25,
  },
];
